import { graphql, navigate } from 'gatsby';
import React from 'react';
import QuoteLoadingPage from 'components/LoadingPages/QuoteLoading';
import { renewalRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { CsMeta } from 'types/contentStack';

type LoadingQuoteProps = {
  data: {
    csInterstitialPage: {
      additional_drivers_quote_summary: {
        meta: CsMeta;
        heading: string;
        subheading: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csInterstitialPage {
      additional_drivers_quote_summary {
        meta {
          meta_title
        }
        heading
        subheading
      }
    }
  }
`;

const QuoteGenerating: React.FC<LoadingQuoteProps & PolicyNumberProps> = ({
  data: {
    csInterstitialPage: { additional_drivers_quote_summary },
  },
  policyNumber,
}) => {
  usePageTracking(additional_drivers_quote_summary.meta.meta_title);

  return (
    <QuoteLoadingPage
      loadingQuoteContent={additional_drivers_quote_summary}
      moveNext={() =>
        navigate(renewalRoutes.quoteSummary(policyNumber), { replace: true })
      }
      moveBack={() => navigate(renewalRoutes.additionalDrivers(policyNumber))}
    />
  );
};

export default withPolicyNumber(QuoteGenerating);
